.App {
  background-color: #F1F1F1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.App-Breadcrumb-Link {
  color: #7BAFDF;
}

.App-Menu-Bar {
  background-color: #0E2361;
  height: 86px;
}

.App-Menu-Bar-Logo {
  width: 158px;
  height: 62px;
  margin-left: -24px;
}

.App-Menu-Bar-Account-Button {
  color: white;
  margin-right: -14px;
}

.App-Menu-Bar-Account-Menu {
  z-index: 1;
  margin-right: 14px;
}

.App-Login-Form,
.App-Password-Reset-Form,
.App-Password-Update-Form {
  margin-top: 80px;
  width: 550px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0;
}

.App-Login-SubForm,
.App-Password-Reset-SubForm,
.App-Password-Update-SubForm {
  width: 320px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.App-Form-Link {
  color: #7BAFDF;
  font-weight: 500;
  text-decoration: none;
}

.App-Form-Link:hover {
  text-decoration: underline;
}

.App-Popla-Logo {
  width: 340px;
  display: block;
  margin: -10px auto 0;
  opacity: 1;
}

.App-Input-TextBox {
  min-height: 56px;
  display: block;
  margin: 15px auto 30px;
}

.App-Login-Header {
  padding-top: 16px;
  padding-bottom: 10px;
}

.App-Button {
  width: 135px;
  height: 41px;
  float: right;
  background-color: #0E2361;
  border-radius: 50px;
}

.App-Login-Link-Password {
  position: relative;
  top: 19px;
  float: right;
}

.App-Password-Reset-Success-Header {
  padding-top: 100px;
  padding-bottom: 10px;
}

.App-Password-Update-TextBox {
  height: 56px;
  display: block;
  margin: 15px auto 70px;
}

.App-Confirm-Password-Update-TextBox {
  height: 56px;
  display: block;
  margin: 15px auto 30px;
}

.App-Login-Link {
  position: relative;
  top: 19px;
}

.App-Evidence-breadcrumbs {
  color: #7BAFDF
}

.App-Evidence-Header {
  margin-top: 45px;
}

.App-Evidence-Summary {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 600;
}

.App-Evidence-comment-box-upper {
  font-size: 14px;
  margin-bottom: 10px;
  color: #212529;
}

.App-Evidence-red {
  color: #FC0303;
}

.App-Evidence-comment-box-lower {
  font-size: 14px;
  margin-top: 0px;
  padding-bottom: 20px;
  color: #212529;
}

.App-Case-summary-section-layout-left {
  min-width: 30%;
  max-width: 30%;
  margin-right: 5px;
}

.App-Case-summary-section-layout-right {
  min-width: 60%;
  max-width: 60%;
  margin-right: 5px;
}

.pointer {cursor: pointer;}
