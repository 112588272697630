body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error { color: red; }

.list-item {
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 16px;
}

.select {
  cursor: pointer;
}

.list-item-data {
  flex: 1;
}

.header {
  background-color: #282c34;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}